import * as React from "react"
import { Container, Row, Col, Form  } from "react-bootstrap"
import {Link}  from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL } from "../components/common/site/constants"

const NotFoundPage = () => (
  <div className="wrapper error-details">
  <Header/>
    <Seo title="404: Not found" />
      <div className="error-page">
        <Container>
          <Row>
            <Col lg={12} className="main-banner content">
              <h1>Oops! Page Cannot Be Found</h1>
              <p><b>The page you are looking for has moved or does not exist!</b> If you are looking for something specific please use the menu to the top of the page, the search below or go back home.</p>
              <div className="btn-wrap">
                <Link class="btn btn-secondary" to={VALUATION_PAGE_URL.alias}>
                  <span>Selling or Letting?</span>Book a Valuation
                </Link>
                <Link class="btn" to={FIND_A_PROPERTY_PAGE_URL.alias}>
                  <span>Buying or Renting?</span>Find a Property
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    <Footer />
  </div>
)

export default NotFoundPage
